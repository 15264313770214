<template>
  <header>
    <center class="pt-5">
      <h1>Error: 404</h1>
      <p>page not found</p>
    </center>
  </header>
</template>

<script>
export default{
  name: '404page'
}
</script>
